<template>
  <div class="dashboard">
    <!-- 资料编辑 -->
    <el-alert type="warning">
      <p>Fill your amazon profile url to increase the chance of passing,earn points reward.</p>
      <el-button type="warning"
                 @click="$router.push('/A_user')">Go to edit profile</el-button>
    </el-alert>
    <!-- 仪表板 -->
    <div class="dashBox">
      <!-- 导航栏 -->
      <div class="dashNav">
        <!-- 搜索 -->
        <div class="dashSeach">
          <i class="el-icon-search"></i>
          <b>Search Freebies</b>
        </div>
        <!-- 其余导航 -->
        <ul>
          <li v-for="(liItem, index) in liList"
              :key="index">
            <div class="liLeft"
                 @click="liClick(liItem)">
              <i :class="liItem.icon"></i>
            </div>
            <div class="liRight">
              <p>{{liItem.navNumber}}</p>
              <p>{{liItem.name}}</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="dashMiddle">
        <!-- 分数详情 -->
        <div class="dashScore">
          <!-- 积分导航栏 -->
          <h1>
            Score Detail
            <span @click="$router.push('/A_scoreDetail')">more ></span>
          </h1>
          <!-- 积分表格 -->
          <el-table :data="tableData"
                    border
                    style="width: 100%">
            <el-table-column prop="points"
                             label="points"
                             width="100">
            </el-table-column>
            <el-table-column prop="detail"
                             label="detail"
                             width="380">
            </el-table-column>
            <el-table-column prop="create_date"
                             label="create_date">
            </el-table-column>
          </el-table>
        </div>
        <!-- 聊天室 -->
        <div class="dashChats">
          <!-- 聊天导航栏 -->
          <h1>
            Chats
            <span @click="$router.push('/A_chats')">more ></span>
          </h1>
          <!-- 未读消息商品 -->
          <div class="unreadBox">
            <!-- 商品栏 -->
            <div class="unreadGood"
                 v-for="(chatItem, index) in unreadList"
                 :key="index">
              <!-- 商品图片 -->
              <div class="unreadImg">
                <img :src="chatItem.product_image_url"
                     alt="" />
              </div>
              <!-- 商品详情 -->
              <div class="unreadDetail">
                <div class="unreadName">
                  <span :class="chatItem.site_class"></span>
                  <span @click="freeGood(chatItem)">{{chatItem.product_title}}</span>
                </div>
                <div class="unreadSell"><b>Seller User</b> : {{chatItem.firstname}} {{chatItem.lastname}}</div>
                <div class="unreadState"><b>Status</b> : {{chatItem.status}}</div>
                <div class="unreadTime">{{chatItem.create_date | formatDate}}</div>
              </div>
              <!-- 聊天室 -->
              <div class="unreadBtn">
                <span class="numberChat" v-if="chatItem.numberId">{{chatItem.numberId}}</span>
                <el-button type="primary"
                           @click="chatClick(chatItem, true)"
                           class="round"
                           size="mini"
                           icon="el-icon-chat-dot-round">Chat</el-button>
              </div>
            </div>
            <!-- 聊天室弹出层 -->
                <el-dialog title="chat"
                           :modal-append-to-body="false"
                           :visible.sync="chatTableVisible"
                           :close-on-click-modal="false"
                           width="600px"
                           :before-close="closeChat">
                  <!-- 聊天室 -->
                  <div class="chatBox">
                    <!-- 聊天内容 -->
                    <div class="textBox"
                         id="textBox"
                         ref="textBox">
                      <!-- 卖方 -->
                      <div :class="chatItem.role === 'RULE_SELLER' ? 'chatLeft' : 'chatRight'"
                          v-for="(chatItem, index) in chatList"
                          :key="index">
                        <div :class="chatItem.role === 'RULE_SELLER' ? 'seller' : 'buyer'">{{chatItem.firstname}} {{chatItem.lastname}}</div>
                        <div :class="chatItem.role === 'RULE_SELLER' ? 'sellerText' : 'buyerText'">
                          <p v-if="chatItem.chatBool === true">{{chatItem.content}}</p>
                          <img v-else
                              :src="chatItem.content"
                              alt=""
                              style="max-height: 400px; max-width: 400px">
                          <p class="time" style="color: white">{{chatItem.create_date | formatDate}}</p>
                        </div>
                        <!-- <div v-if="chatItem.chatBool === false"
                            :class="chatItem.role === 'RULE_SELLER' ? 'sellerText' : 'buyerText'">
                          <img :src="chatItem.content"
                              alt="">
                          <p class="time">{{chatItem.create_date | formatDate}}</p>
                        </div> -->
                      </div>
                    </div>
                    <div class="sendText">
                      <textarea name=""
                                id=""
                                cols="60"
                                rows="8"
                                v-model="chatTest.chatInpout"></textarea>
                      <!-- <i class="el-icon-picture-outline"></i> -->
                      <el-upload class="upload-demo"
                                  action="https://crediscounts.com/photograph/picture"
                                  :headers="chatToken"
                                  :on-success="handlePreview"
                                  :on-remove="handleRemove"
                                  :before-upload="beforeAvatarUpload"
                                  list-type="picture"
                                  ref='upload'>
                        <i class="el-icon-picture-outline"></i>
                      </el-upload>
                    </div>
                    <div class="send">
                      <el-button type="primary" @click="closeChat">Cancel</el-button>
                      <el-button type="primary"
                                 @click="sendChat()" :loading="$store.state.loadingBool">Send</el-button>
                    </div>
                  </div>
                </el-dialog>
          </div>
        </div>
      </div>
      <!-- 步骤 -->
      <div class="dashStep">
        <div class="stepSeh"
             v-for="(stepItem, index) in stepList"
             :key='index'>
          <div class="stepNum">{{stepItem.num}}</div>
          <div class="stepXian"></div>
          <div class="stepBox">
            <h4 style="cursor: pointer;" @click="stepClick(stepItem)">{{stepItem.nameH}}</h4>
            <p>{{stepItem.nameP}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/// 引入接口
import {
  pointDetail, // 积分详情
  allChat, // 所有未读消息
  count, // 用户未读消息
  chatGet, // 用户获取聊天信息
  chat, // 用户发送聊天消息
  findNotificationCounts // 赠品对应状态订单
} from '../../api/buyerAfter'
import {
  getToken
} from '../../utils/token'
// 日期格式化
import moment from 'moment'
import { formatDate } from '../../utils/data.js'
export default {
  data () {
    return {
      // 其余导航列表
      liList: [
        { name: 'Requests', icon: 'el-icon-document', navUrl: '/A_requests', navId: 1 },
        { name: 'To Order', icon: 'el-icon-more', navUrl: '/A_toOrder', navId: 2 },
        { name: 'Validate', icon: 'el-icon-s-goods', navUrl: '/A_submitReview', navId: 3 },
        { name: 'Waiting', icon: 'el-icon-circle-check', navUrl: '/A_waiting', navId: 7 },
        { name: 'Finished', icon: 'el-icon-trophy', navUrl: '/A_finsihed', navId: 8 }
      ],
      // 步骤表
      stepList: [
        { num: '1', navUrl: '/A_requests', nameH: ' Requests ', nameP: 'Click the "Requests" button and Waiting for audit' },
        { num: '2', navUrl: '/A_toOrder', nameH: 'To Order ', nameP: 'Get seller confirmation and buy on Amazon within 7 days' },
        { num: '3', navUrl: '/A_submitReview', nameH: 'Submit review', nameP: 'Submit reviews after receiving the product' },
        { num: '4', navUrl: '/A_waiting', nameH: ' Waiting ', nameP: ' Finished ' }
      ],
      // 积分列表
      tableData: [],
      // 聊天室弹出层
      chatTableVisible: false,
      // fileList: [
      //   { name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' },
      //   { name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' }
      // ],
      // 未读列表
      unreadList: [],
      // 消息列表
      chatList: [],
      // 消息内容
      // chatInpout: '',
      chatToken: '',
      // 消息表单
      chatTest: {
        fileList: [],
        chatInpout: ''
      }
    }
  },
  filters: {
    // 时间格式化
    formatDate (time) {
      const date = new Date(time)
      // // (new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm')
    }
  },
  created () {
    this.chatToken = { Authorization: getToken() }
    // 积分详情
    pointDetail({
      page: '1',
      size: '10',
      userId: localStorage.getItem('buyer-userId')
    }).then(result => {
      if (result.data.code === 200) {
        this.tableData = result.data.data.rows
        this.currentTotal = result.data.data.total
        this.tableData.map(v => {
          v.create_date = moment(v.create_date).format('YYYY-MM-DD hh:mm')
          if (v.source_type === 2) {
            v.points = -v.points
          } else {
            v.points = '+' + v.points
          }
        })
      }
    }).catch(err => {
      return err
    })
    // 赠品对应状态订单
    findNotificationCounts().then(result => {
      if (result.data.code === 200) {
        this.liList.map(v => {
          for (const key in result.data.data.dto_map) {
            if (parseInt(key) === v.navId) {
              v.navNumber = result.data.data.dto_map[key]
            }
          }
        })
      }
    }).catch(err => {
      return err
    })
    this.getallChat()
    this.$store.commit('getLoading', false)
  },
  methods: {
    // 导航点击跳转路由
    liClick (item) {
      this.$router.push(`${item.navUrl}`)
    },
    // 步骤路由跳转
    stepClick (item) {
      this.$router.push(`${item.navUrl}`)
    },
    // 未读消息
    getallChat () {
      // 未读消息
      allChat({
        page: '',
        siez: ''
      }).then(result => {
        if (result.data.code === 200) {
          // console.log(result)
          result.data.data.rows.map(v => {
            for (const key in this.$store.state.freeStatusList) {
              if (v.status === parseInt(this.$store.state.freeStatusList[key])) {
                v.status = key
              }
            }
            count({
              freeRequestId: v.id
            }).then(result => {
              if (result.data.code === 200) {
                if (result.data.data !== 0) {
                  v.numberId = result.data.data
                }
                this.unreadList.push(v)
              }
            }).catch(err => {
              return err
            })
          })
        }
      }).catch(err => {
        return err
      })
    },
    // 聊天室弹出层控制
    closeChat () {
      this.chatTableVisible = false
      this.getallChat()
    },
    // 聊天室列表
    chatClick (item, boolean) {
      // console.log(this.unreadList)
      if (boolean) {
        this.unreadList.map(v => {
          if (item.id === v.id) {
            delete v.numberId
          }
        })
        this.chatTableVisible = true
      }
      localStorage.setItem('buyer-item', JSON.stringify(item))
      let chatNum = 1
      this.timer = setInterval(() => {
        if (chatNum === 0) {
          clearInterval(this.timer)
        } else {
          chatNum--
          chatGet({
            freeRequestId: item.id
          }).then(result => {
            if (result.data.code === 200) {
              result.data.data.rows.map(v => {
                if (v.content.indexOf('photograph') < 0) {
                  v.chatBool = true
                } else {
                  v.chatBool = false
                }
              })
              this.chatList = result.data.data.rows
              this.$nextTick(() => {
                const div = document.getElementsByClassName('textBox')[0]
                div.scrollTop = div.scrollHeight
              })
            }
            // ('resultChat =>', result)
          }).catch(err => {
            return err
          })
        }
      }, 10)
    },
    textScroll (item) {
      // this.$nextTick(() => {
      //   const div = document.getElementById('textBox')
      //   if (div.scrollTop < localStorage.getItem('buyer-scrollTop')) {
      //     clearInterval(this.timer)
      //     this.timer = setInterval(() => {
      //       chatGet({
      //         freeRequestId: item.id
      //       }).then(result => {
      //         if (result.data.code === 200) {
      //           this.chatList = result.data.data.rows
      //         }
      //         // ('result =>', result)
      //       }).catch(err => {
      //         return err
      //       })
      //     }, 1000)
      //   }
      //   // ('div ==>', div.scrollTop)
      // })
    },
    // 图片删除
    handleRemove (file, fileList) {
      this.chatTest.fileList = []
      fileList.map(v => {
        this.chatTest.fileList.push(`https://crediscounts.com/photograph/images/${v.response.message}`)
      })
    },
    // 图片上传成功
    handlePreview (file, fileList) {
      this.chatTest.fileList.push(`https://crediscounts.com/photograph/images/${fileList.response.message}`)
    },
    // 图片上传之前
    beforeAvatarUpload (file) {
      // (this.chatTest.fileList)
      const srcSize = file.size < (3072 * 3072)
      const srcReg = /image/g
      const srcBool = srcReg.test(file.type)
      if (!srcBool) {
        this.$message.error('Please upload the address')
        return false
      } else {
        if (!srcSize) {
          this.$message.error('The image size is less than 3MB')
          return false
        } else if (this.chatTest.fileList.length > 2) {
          this.$message.error('Send up to three pictures at a time')
          return false
        }
      }
    },
    // 发送消息
    sendChat () {
      const item = JSON.parse(localStorage.getItem('buyer-item'))
      for (const key in this.chatTest) {
        if (key === 'chatInpout') {
          if (this.chatTest.chatInpout === '' && this.chatTest.fileList.length === 0) {
            this.$store.commit('getLoading', false)
            this.$message.error('Cannot send empty message')
          } else {
            // console.log('aaa')
            this.$store.commit('getLoading', true)
            this.sendChattest(item, this.chatTest.chatInpout)
          }
        } else if (key === 'fileList') {
          this.$store.commit('getLoading', true)
          this.chatTest.fileList.map(v => {
            this.sendChattest(item, v)
          })
        }
      }
      // chat({
      //   addVO: {
      //     content: this.chatInpout,
      //     free_request_id: item.id
      //   }
      // }).then(result => {
      //   // ('result ==>', result)
      //   if (result.data.code === 200) {
      //     this.$store.commit('getLoading', false)
      //     this.chatInpout = ''
      //     clearInterval(this.timer)
      //     this.timer = setInterval(() => {
      //       chatGet({
      //         freeRequestId: item.id
      //       }).then(result => {
      //         if (result.data.code === 200) {
      //           this.chatList = result.data.data.rows
      //           this.$nextTick(() => {
      //             const div = document.getElementById('textBox')
      //             div.scrollTop = div.scrollHeight
      //             localStorage.setItem('buyer-scrollTop', div.scrollTop)
      //           })
      //         }
      //         // ('result =>', result)
      //       }).catch(err => {
      //         return err
      //       })
      //     }, 1000)
      //   }
      // }).catch(err => {
      //   return err
      // })
      // (item)
    },
    // 发送消息
    sendChattest (item, v) {
      // 用户发送消息
      chat({
        addVO: {
          content: `${v}`,
          free_request_id: item.id
        }
      }).then(result => {
        // ('result ==>', result)
        if (result.data.code === 200) {
          this.$store.commit('getLoading', false)
          localStorage.removeItem('buyer-item')
          this.chatTest.chatInpout = ''
          this.chatClick(item, false)
          this.$refs.upload.clearFiles()
        }
      }).catch(err => {
        return err
      })
    },
    // 进入赠品页面
    freeGood (item) {
      // (item)
      this.$router.push({ path: '/F_freeDetails', query: { goodUrl: item.url, goodId: item.product_id } })
    }
  }
}
</script>

<style lang='less' scoped>
.dashboard {
  margin: 0 20px 20px 20px;
  .el-alert--warning.is-light {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }
  p {
    margin-bottom: 16px;
    font-size: 16px;
    color: #856404;
  }
  .el-button--warning {
    float: left;
  }
  //仪表板
  .dashBox {
    overflow: hidden;
    //导航栏
    .dashNav {
      margin-top: 15px;
      background-color: white;
      padding: 15px;
      display: flex;
      justify-content: space-between;
      //搜索
      .dashSeach {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        i {
          width: 100%;
          font-size: 40px;
          text-align: center;
        }
        b {
          font-size: 14px;
          margin-top: 10px;
          line-height: 1.5;
        }
      }
      //其余导航
      ul {
        width: 85%;
        display: flex;
        justify-content: space-between;
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .liLeft {
            cursor: pointer;
            width: 60px;
            height: 60px;
            background-color: #ff9933;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            i {
              font-size: 40px;
              color: white;
            }
          }
          .liRight {
            margin-left: 10px;
            flex-wrap: wrap;
            display: flex;
            align-items: flex-end;
            p {
              font-size: 14px;
              font-weight: 700;
              width: 100%;
              color: black;
              margin: 0;
              line-height: 1.5;
              margin-top: 10px;
            }
          }
        }
      }
    }
    .dashMiddle {
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      //分数详情
      .dashScore {
        width: 65%;
        background-color: white;
        margin-right: 10px;
        padding: 10px;
        box-sizing: border-box;
        h1 {
          margin-top: 20px;
          font-size: 20px;
          position: relative;
          padding: 10px 0;
          border-bottom: 1px solid #eee;
          display: flex;
          justify-content: space-between;
          span {
            cursor: pointer;
            font-size: 14px;
            position: absolute;
            right: 0;
            line-height: 30px;
          }
        }
      }
      //聊天室
      .dashChats {
        width: 35%;
        background-color: white;
        padding: 10px;
        box-sizing: border-box;
        min-height: 400px;
        h1 {
          margin-top: 20px;
          font-size: 20px;
          position: relative;
          padding: 10px 0;
          border-bottom: 1px solid #eee;
          display: flex;
          justify-content: space-between;
          span {
            cursor: pointer;
            font-size: 14px;
            position: absolute;
            right: 0;
            line-height: 30px;
          }
        }
        .unreadBox {
          padding-top: 20px;
          overflow: hidden;
          .unreadGood {
            width: 100%;
            box-sizing: border-box;
            padding: 10px 20px;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
            margin-bottom: 20px;
            display: flex;
            justify-content: space-around;
            flex-wrap: nowrap;
            align-items: center;
            .unreadImg {
              width: 80px;
              height: 80px;
              margin-right: 15px;
              img {
                width: 80px;
                max-height: 80px;
              }
            }
            .unreadDetail {
              width: 50%;
              // flex: 2;
              // width: 50%;
              // margin: 0px 20px;
              .unreadName {
                width: 50%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin: 5px 0;
                span {
                  line-height: 18px;
                  &:nth-of-type(2) {
                    margin-left: 10px;
                    cursor: pointer;
                  }
                }
              }
              .unreadSell {
                margin-bottom: 10px;
                width: 95%;
                line-height: 2;
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
              }
              .unreadState {
                margin-bottom: 10px;
              }
              .unreadTime {
                margin-bottom: 10px;
              }
            }
            .unreadBtn {
              position: relative;
              // flex: 1;
              // margin-left: 10px;
              .el-button {
                background-color: #20a8d8;
                border-color: #20a8d8;
              }
              .numberChat {
                position: absolute;
                right: -10px;
                top: -10px;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background: #f86c6b;
                color: white;
                text-align: center;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
    //步骤
    .dashStep {
      margin-top: 15px;
      background-color: white;
      padding: 15px;
      display: flex;
      justify-content: space-between;
      .stepSeh {
        width: 24%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        .stepNum {
          font-size: 50px;
          line-height: 1.5;
          color: #ff9966;
        }
        .stepXian {
          width: 30px;
          border-bottom: 2px solid #cbcbcb;
          display: flex;
          align-items: center;
          // color: red;
        }
        .stepBox {
          font-size: 14px;
          text-align-last: center;
          width: 220px;
          h4 {
            text-align: center;
            font-size: 18px;
            margin-bottom: 10px;
          }
          p {
            text-align: center;
            font-size: 14px;
          }
        }
      }
    }
  }
}

</style>
